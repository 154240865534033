.maintenance-status {
    border-radius: 4px;
    font-size: 11px;
    padding: 5px;
    text-align: center;
}

.maintenance-status.empty {
    background-color: #F8F9FA;
}

.maintenance-status.internal-error {
    background-color: #343A40;
    color: #FFFFFF;
}

.maintenance-status.ko {
    background-color: #DC3545;
    color: #FFFFFF;
}

.maintenance-status.ok {
    background-color: #28A745;
    color: #FFFFFF;
}

.maintenance-status.warning {
    background-color: #FFC107;
}