@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700");

.loginWrapper {
    color: #686868  !important;
    background: #f5f5f5;
    font-family: 'Open Sans', sans-serif  !important;
    padding-top: 47px;
}

.loginWrapper .border-bottom {
    border-bottom: 1px solid #efefef !important;
}

.loginWrapper .text-center {
    text-align: center !important;
}

.loginWrapper .margin-none {
    margin: 0 !important;
}

@media (min-width: 1199px) {
    .loginWrapper .spacing-x2.innerAll,
    .loginWrapper .spacing-x2 .innerAll,
    .loginWrapper .spacing-x2 .widget.widget-tabs-vertical .widget-body .tab-content,
    .loginWrapper .spacing-x2 .box-generic {
        padding: 25px;
    }
}

.loginWrapper .innerAll {
    padding: 15px;
    position: relative;
}

.loginWrapper #content {
    margin: 0 auto;
    max-width: 768px;
    background: #f5f5f5;
    padding-bottom: 40px;
}

.loginWrapper .login .placeholder {
    font-size: 120px;
    color: #dfdfdf;
}

html.csstransitions.csstransforms3d a, html.csstransitions.csstransforms3d .btn, html.csstransitions.csstransforms3d .navbar.main li, html.csstransitions.csstransforms3d .navbar.main .caret {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

.loginWrapper .btn-primary {
    background: #5677fc;
    border-color: #5677fc;
    color: #ffffff;
}

.loginWrapper .btn-block {
    width: 100%;
    min-height: 30px;
}

.loginWrapper .btn {
    text-shadow: none;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
}

.loginWrapper .btn-group.open .btn-primary.dropdown-toggle,
.loginWrapper .btn-primary.disabled,
.loginWrapper .btn-primary[disabled],
.loginWrapper .btn-primary:hover,
.loginWrapper .btn-primary:focus,
.loginWrapper .btn-primary:active,
.loginWrapper .btn-primary.active {
    background: none;
    color: #5677fc;
    border-color: #5677fc;
}

.loginWrapper .btn-link,
.loginWrapper .btn-link:hover {
    color: #5677fc;
}

.loginWrapper .btn-link {
    background-color: transparent;
    padding: 0;
}

.loginWrapper *,
.loginWrapper .btn-link:focus {
    outline: none !important;
}

.loginWrapper div[class*="col-xs-"], .loginWrapper div[class*="col-sm-"], .loginWrapper div[class*="col-md-"], .loginWrapper div[class*="col-lg-"], li[class*="col-xs-"], li[class*="col-sm-"], li[class*="col-md-"], li[class*="col-lg-"] {
    padding: 0 7.5px;
}

.loginWrapper input[type=text], .loginWrapper input[type=password], .loginWrapper select, .loginWrapper textarea {
    border-color: #e5e5e5;
    color: #555;
}

.loginWrapper select, .loginWrapper textarea, .loginWrapper input[type="text"], .loginWrapper input[type="password"], .loginWrapper input[type="datetime"], .loginWrapper input[type="datetime-local"], .loginWrapper input[type="date"], .loginWrapper input[type="month"], .loginWrapper input[type="time"], .loginWrapper input[type="week"], .loginWrapper input[type="number"], .loginWrapper input[type="email"], .loginWrapper input[type="url"], .loginWrapper input[type="search"], .loginWrapper input[type="tel"], .loginWrapper input[type="color"], .loginWrapper .uneditable-input {
    font-size: 13px;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
    border-color: #e5e5e5;
}

.loginWrapper .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.loginWrapper .login form > .form-group {
    margin-bottom: 0;
}

.loginWrapper label, .loginWrapper input, .loginWrapper select, .loginWrapper textarea {
    font-size: 13px;
}

.loginWrapper .form-control:focus {
    border-left: 1px solid #5677fc;
}