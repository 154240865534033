.maintenance-threshold {
    text-align: center;
}

.maintenance-threshold span {
    border-radius: 12px;
    font-size: 12px;
    padding: 5px 10px;
    text-align: center;
}

.maintenance-threshold .error {
    background-color: #DC3545;
    color: #FFFFFF;
}

.maintenance-threshold .warning {
    background-color: #FFC107;
    margin-right: 10px;
}