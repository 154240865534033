.tagSelectDiv {
    display: inline;
}

.tagSelectDiv .Select {
    display: inline-flex;
    width: 196px;
}

/* @media (min-width: 1282px) {
    .header-navbar .container {
        width: 1220px;
    }
} */

.header-navbar .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.header-navbar .container-fluid .navbar-collapse.collapse {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.header-navbar .container-fluid .navbar-collapse.collapse .search-bar .form-group {
    padding: 0 4px;
}

.search-criteria > button {
    margin: 0 8px;
}

#dev-mode-team-select-tooltip p {
    width: 100%;
    max-width: 100%;
}

#dev-mode-team-select-tooltip span {
    width: 100%;
    max-width: 100%;
    display: block;
    color: #777777;
    font-size: 11px;
    text-align: justify;
    padding: 0;
}