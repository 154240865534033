.nav-tabs {
    margin-bottom: 15px;
}

.small-control {
    margin-bottom: 0px;
}

.small-control select.form-control {
    padding: 0px;
}

.navbar-brand {
    padding: 0px;
}

.loading-overlay {
    top: 0; right: 0; bottom: 0; left: 0;
    position: absolute;
    background-color: rgba(0,0,0,0.33);
    text-align: center;
    border-radius: 4px;
    color: white;
    text-shadow: 0px 0px rgba(0,0,0,0.5) ;
    z-index: 1000;
}

.loading-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50%;
    height: 10%;
    margin: auto;
}

.with-loading{
    position: relative;
}

.with-panel-loading .loading-overlay{
    margin-left: 16px;
    margin-right: 16px;
}
@media (min-width: 992px){
    .with-panel-loading .loading-overlay{
        margin-bottom: 20px;
    }
}

td {
    white-space: pre-wrap;
}

.coucou {
    background: url(/assets/images/canari/canari.png);
}

hr{
    margin-top:  0px;
}

small > hr {
    margin-top:  0.3em;
    margin-bottom: 0.3em;
}

@media (min-width: 768px){
    .navbar-nav>.active>a, .navbar-nav>.active>a:hover, .navbar-nav>li>a:hover {
        box-shadow: 0px -3px 0px #F9CE1D inset!important;
        -webkit-box-shadow: 0px -3px 0px #F9CE1D inset!important;
}
}

.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    background-color: #080808;
}

.footer {
    margin: 6px 0;
}

.cursor-pointer{
    cursor:pointer;
}


.panel-heading {
    font-size: 16px;
}

.panel-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 5px;
}

.label-special {
    background-color: transparent;
    color: #333;
    font-weight: normal;
    font-size: 85%;
    padding: 0;
}


.label-with-info {
    padding-right: 0.2em;
}

.label-with-info > span {
    background: white;
    color: #333;
    padding-right: 0.2em;
    padding-bottom: 0.1em;
    padding-left: 0.2em;
    border-top-right-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
}

.label-block {
    display: inline-block;
    white-space: pre-wrap;
}

.label-0{
     background-color: #7f8c8d;
}

.label-1{
    background-color: #4442C2;
}

.label-2{
    background-color: #2BBE83;
}

.label-3{
    background-color: #FF753A;
}

.label-4{
    background-color: #458A8A;
}

.label-5{
    background-color: #8CB23F;
}

.label-6{
    background-color: #B85C90;
}

.label-7{
    background-color: #E67373;
}

.label-8{
    background-color: #6981E3;
}

.label-9{
    background-color: #38DC6A;
}

.label-10{
    background-color: #E5543D;
}

.label-11{
    background-color: #F3AE0E;
}
.label-12{
    background-color: #35A279;
}
.label-13{
    background-color: #4B47A8;
}
.label-14{
    background-color: #AD4211;
}
.label-15{
    background-color: #D98F4F;
}
.label-16{
    background-color: #B0407D;
}
.label-17{
    background-color: #82B128;
}
.label-18{
    background-color: #307F83;
}

.label-t-0{
    color: #7f8c8d;
    background-color: transparent;
}

.label-t-1{
    color: #4442C2;
    background-color: transparent;
}

.label-t-2{
    color: #2BBE83;
    background-color: transparent;
}

.label-t-3{
    color: #FF753A;
    background-color: transparent;
}

.label-t-4{
    color: #458A8A;
    background-color: transparent;
}

.label-t-5{
    color: #8CB23F;
    background-color: transparent;
}

.label-t-6{
    color: #B85C90;
    background-color: transparent;
}

.label-t-7{
    color: #E67373;
    background-color: transparent;
}

.label-t-8{
    color: #6981E3;
    background-color: transparent;
}

.label-t-9{
    color: #38DC6A;
    background-color: transparent;
}

.label-t-10{
    color: #E5543D;
    background-color: transparent;
}

.label-t-11{
    color: #F3AE0E;
    background-color: transparent;
}
.label-t-12{
    color: #35A279;
    background-color: transparent;
}
.label-t-13{
    color: #4B47A8;
    background-color: transparent;
}
.label-t-14{
    color: #AD4211;
    background-color: transparent;
}
.label-t-15{
    color: #D98F4F;
    background-color: transparent;
}
.label-t-16{
    color: #B0407D;
    background-color: transparent;
}
.label-t-17{
    color: #82B128;
    background-color: transparent;
}
.label-t-18{
    color: #307F83;
    background-color: transparent;
}

.filter-date-picker .DateInput_input__small {
    font-size: 14px;
    line-height: 15px;
    padding: 7px 7px 4px;
}

.form-group-sm > .Select > .Select-control {
    height: 28px;
    border-radius: 3px;
}

.form-group-sm > .Select > .Select-control > span > .Select-value, .form-group-sm > .Select > .Select-control > span > .Select-placeholder, .form-group-sm > .Select > .Select-control > span > .Select-input{
    line-height: 28px;
    padding: 0px;
    font-size: 12px;
}

.form-group-sm > .Select > .Select-control > span > .Select-input {
    height: 28px;
    margin-left: 2px;
}

.form-group-sm > .Select > .Select-control > .Select-multi-value-wrapper > .Select-input {
    height: 28px;
}

.form-group-sm > .Select > .Select-control > .Select-multi-value-wrapper > .Select-placeholder {
    line-height: 28px;
}

.form-group-sm > .Select > .Select-control > .Select-multi-value-wrapper > .Select-input > input {
    padding: 4px 0 7px;
}

.form-group-sm > .Select > .Select-menu-outer {
    line-height: 1.5;
    padding: 0px;
    margin-left: 2px;
    font-size: 12px;
}

.form-group-sm > .Select--multi .Select-value-icon, .form-group-sm > .Select--multi .Select-value-label {
    display: inline;
    padding: 0px;
}

.pill-primary {
    color:#337ab7;
}

.pill-info {
    color:#5bc0de;
}

.pill-success {
    color:#5cb85c;
}

.pill-warning {
    color:#f0ad4e;
}

.pill-danger {
    color:#d9534f;
}

.pill-default {
    color:#777777;
}

@media (min-width: 992px) {
    .vertical-align {
        display: flex;
        align-items: center;
    }
}

.orange::before{
    content: url("/images/operators/orange.png");
    vertical-align: -100%;
    padding-right: 16px;
}

.sfr::before{
    content: url("/images/operators/sfr.jpg");
    vertical-align: -100%;
    padding-right: 16px;
}

.free::before{
    content: url("/images/operators/free.png");
    vertical-align: -100%;
    padding-right: 16px;
}

.bouygues::before{
    content: url("/images/operators/bouygues.png");
    vertical-align: -100%;
    padding-right: 16px;
}

.alert h1, .alert .h1, .alert h2, .alert .h2, .alert h3, .alert .h3 {
    margin-top: 0px;
}

.btn-violet {
    background-color: #41238e !important;
    border-color: #41238e !important;
    background-image: linear-gradient(to bottom, #6945c5 0%, #41238e 100%)
}

fieldset.custom-fieldset-blue {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 1px;
    border-style: groove;
    border-color: #337ab7;
    border-image: initial;
}

legend {
    border: none;
    color: #265a88;
    font-size: unset;
    padding: 0 5px;
    width: auto;
}

.no-resize {
    resize: none;
}

.resize-vertical {
    resize: vertical;
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0;
}

.mt-1 {
    margin-top: 5px;
}

.mr-1 {
    margin-right: 5px;
}

.none {
    display: none;
}

.flex {
    display: flex;
}

.flex.justify-space-between {
    justify-content: space-between;
}
    
.flex.align-center {
    align-items: center;
}

.grey {
    color: #9E9E9E;
}

.app-version-cell.rt-td.rt-expandable:has(> .rt-expander.-open) {
    background-color: #d9edf7;
}

.app-version-cell.rt-td:not(.rt-expandable) {
    padding: 0;
}

.app-version-cell.rt-td:not(.rt-expandable) > .expandable-cell {
    padding: 7px 5px;
    min-height: 34px;
}

.app-version-history-subtable.-striped .rt-tr.-odd {
    background: rgba(0,0,0,0.1);
}

.app-version-history-subtable.-striped .rt-tr.-even {
    background: rgba(0,0,0,0.15);
}