.maintenance-table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}

.maintenance-table th {
    font-size: 12px;
    text-align: center;
}

.maintenance-table tbody tr {
    border-radius: 4px;
    box-shadow: 0 3px 2px rgb(0 0 0 / 20%);
}

.maintenance-table tbody td {
    font-size: 13px;
    padding: 10px;
}

.maintenance-table tbody td.dark-grey {
    background-color: #EBEBEB;
}

.maintenance-table tbody td.light-grey {
    background-color: #F9F9F9;
}

.maintenance-table tbody td.black {
    background-color: #262626;
}

.maintenance-table tbody td.first-cell {
    border-radius: 4px 0 0 4px;
}

.maintenance-table tbody td.last-cell {
    border-radius: 0 4px 4px 0;
    padding: 0;
}
