.maintenance-group {
    border-bottom: 3px solid #E4E4E4;
    margin: auto;
    padding: 50px 0;
    width: 80%;
}

@media (min-width: 1282px) {
    .maintenance-group .container {
        width: 90%;
    }
}

.maintenance-group .title {
    font-size: 24px;
    font-weight: bold;
}