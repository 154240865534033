.singleDatePickerInputGroup.inError .SingleDatePickerInput {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede;
}

.SingleDatePicker,
.SingleDatePicker .SingleDatePickerInput,
.SingleDatePicker .DateInput {
  display: block;
  width: 100%;
}

.SingleDatePicker_picker {
  z-index: 3;
}

.CalendarDay__default {
  color: #00A699;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: #cacccd;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #ffffff;
  color: #aa0000;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  color: #fff;
}
